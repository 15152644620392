<template>
  <moe-page title="新建抽奖">
    <moe-card class="mb-20" :autoHeight="true">
      <template slot="header">
        <moe-steps :active="active" :stepsList="[{ title: '基本信息设置' }, { title: '奖品设置' }, { title: '转盘设置' }]"></moe-steps>
      </template>

      <moe-form
        class="default-form"
        ref="luckyGridForm"
        :showBack="false"
        :model="luckyGridParams"
        :rules="rules">
        <template v-if="active === 0">
          <div style="width: 50%;" class="margin-0-auto">
            <div class="fwb mb-20 font-20">基本信息</div>
            <el-form-item label="活动名称" prop="name" label-width="100px">
              <el-input class="w-300" v-model.trim="luckyGridParams.name" placeholder="请输入活动名称，2到20个文字组合" clearable maxlength="20"></el-input>
            </el-form-item>
            <el-form-item label="活动时间" prop="startTime" label-width="100px">
              <el-date-picker
                class="w-500"
                :disabled="IS_EDIT"
                placement="bottom-start"
                v-model="datetime"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                @change="(datetime) => ([luckyGridParams.startTime, luckyGridParams.endTime] = datetime || ['', ''])" />
            </el-form-item>
            <el-form-item label="抽奖次数" prop="sendCondition" label-width="100px">
              <div class="df aic wsn">
                <el-checkbox v-model="luckyGridParams.buySend"></el-checkbox>
                <div class="ml-10">订单满</div>
                <div class="ml-10 mr-10">
                  <el-input
                    :disabled="!luckyGridParams.buySend"
                    class="w-200"
                    :value="luckyGridParams.sendCondition"
                    placeholder="请输入"
                    maxLength="10"
                    clearable
                    @input="(value) => luckyGridParams.sendCondition = $moe_formatter.formatterMoney(value)">
                    <template slot="append">元</template>
                  </el-input>
                </div>
                <div>获得一次抽奖机会，每人最多获得</div>
                <div class="ml-10 mr-10">
                  <el-input
                    :disabled="!luckyGridParams.buySend"
                    class="w-200"
                    :value="luckyGridParams.sendCount"
                    placeholder="请输入"
                    maxLength="6"
                    clearable
                    @input="(value) => luckyGridParams.sendCount = $moe_formatter.formatterInteger(value)">
                    <template slot="append">次</template>
                  </el-input>
                </div>
              </div>
            </el-form-item>
          </div>
        </template>

        <template v-if="active === 1">
          <div class="fwb mb-20 font-20">奖品设置</div>
          <div style="height: 1000px;min-height: 1000px;" class="df fdc">
            <moe-table
              :maxHeight="1000"
              :stripe="true"
              :data="luckyGridParams.itemList"
              :numberShow="false"
              :mode="false"
              emptyText="请添加奖品">
              <el-form-item prop="itemList" slot="tool">
                <el-button
                  :disabled="luckyGridParams.itemList.length === itemListTotal"
                  type="primary"
                  icon="el-icon-circle-plus-outline"
                  @click="form.showDialog = true;">{{ `添加奖品（${luckyGridParams.itemList.length}/${itemListTotal}）` }}</el-button>
              </el-form-item>

              <el-table-column label="奖品名称" prop="name" min-width="120" :show-overflow-tooltip="true" />
              <el-table-column label="奖品类型" min-width="150" :formatter="formatterPrizeType" />
              <el-table-column label="发放总量" min-width="100" prop="total" />
              <el-table-column label="转盘图片">
                <template slot-scope="{ row }">
                  <moe-image :src="row.image" width="80px" height="80px" />
                </template>
              </el-table-column>
              <el-table-column label="操作" fixed="right" width="200">
                <template slot-scope="{ row, $index }">
                  <el-button icon="el-icon-delete" size="small" type="danger" @click="handleDelete('luckyGridForm', row, $index)">删除</el-button>
                </template>
              </el-table-column>
            </moe-table>
          </div>
        </template>

        <template v-if="active === 2">
          <div class="fwb mb-20 font-20">转盘设置</div>
          <div style="height: 1000px;min-height: 1000px;" class="df fdc">
            <moe-table
              :maxHeight="1000"
              :stripe="true"
              :data="luckyGridParams.itemConfigList"
              :numberShow="false"
              :mode="false"
              emptyText="请添加奖品">
              <el-table-column label="转盘位置" :formatter="formatterIndex" />
              <el-table-column label="奖品" min-width="300" prop="total">
                <div slot-scope="{ row, $index }" class="df aic jcc mt-20">
                  <el-form-item :prop="`itemConfigList.${$index}.lotteryItemId`" :rules="rules.lotteryItemId">
                    <el-select class="w-300" v-model="row.lotteryItemId" filterable clearable placeholder="请选择奖品" @change="(value) => changeLotteryItemId(value, $index)">
                      <el-option v-for="(item, index) in luckyGridParams.itemList" :key="index" :label="item.name" :value="item.id" />
                    </el-select>
                  </el-form-item>
                </div>
              </el-table-column>
              <el-table-column label="转盘图片" min-width="200">
                <template slot-scope="{ row }">
                  <moe-image v-if="row.image" :src="row.image" width="80px" height="80px" />
                </template>
              </el-table-column>
              <el-table-column :label="`转到概率(总概率${ALL_RATE_COMPUTED}%)`" min-width="300">
                <div class="df aic jcc" slot-scope="{ row, $index }">
                  <el-form-item :prop="`itemConfigList.${$index}.rate`" :rules="rules.rate">
                    <el-input
                      class="w-300"
                      :value="row.rate"
                      maxLength="5"
                      @input="(value) => changeRate(value, $index)" placeholder="请输入转到概率" clearable>
                      <template slot="append">%</template>
                    </el-input>
                  </el-form-item>
                </div>
              </el-table-column>
            </moe-table>
          </div>
        </template>

        <template slot="tool">
          <el-button v-if="active === 0" type="info" icon="el-icon-close" @click="$moe_coordinator.navigateBack()">关闭</el-button>
          <el-button v-else type="primary" plain icon="el-icon-back" :loading="luckyGridLoad" @click="handleStep('back', active - 1)">{{ luckyGridLoad ? '保存中' : '上一步' }}</el-button>
          <el-button v-if="active === 2" type="primary" icon="el-icon-thumb" :loading="luckyGridLoad" @click="handleSubmit()">{{ luckyGridLoad ? '保存中' : '保存' }}</el-button>
          <el-button v-else type="primary" icon="el-icon-right" :loading="luckyGridLoad" @click="handleStep('right', active + 1)">下一步</el-button>
        </template>
      </moe-form>
    </moe-card>

    <moe-dialog :show="form.showDialog" title="添加奖品" width="70%" @close="handleDialogClose()">
      <div class="dialog_content">
        <moe-form
          class="default-form"
          ref="prizeForm"
          :showback="false"
          :showTool="false"
          :model="form"
          :rules="formRules">
          <el-form-item label="奖品名称" prop="name">
            <el-input class="w-300" v-model.trim="form.name" placeholder="请输入奖品名称，2到20个文字组合" clearable maxlength="20"></el-input>
          </el-form-item>
          <el-form-item label="活动内容" prop="prizeType">
            <moe-radio-group :radioBoxList="$moe_data.prizeTypeList" v-model="form.prizeType" @change="changePrizeType"></moe-radio-group>
          </el-form-item>
          <template v-if="['COUPON', 'GIFT'].includes(form.prizeType)">
            <div style="height: 300px;min-height: 300px;" class="df fdc" >
              <moe-table
                :numberShow="false"
                :data="form.itemList"
                :mode="false">
                <el-form-item prop="itemList" slot="tool">
                  <el-button
                    :disabled="form.itemList.length ? true : false"
                    type="primary"
                    icon="el-icon-circle-plus-outline"
                    @click="handleShowDialog()">
                    {{ `添加${$moe_format.getPrizeTypeList(form.prizeType)}`}}
                  </el-button>
                </el-form-item>

                <template v-if="form.prizeType === 'COUPON'">
                  <el-table-column label="活动信息" min-width="150">
                    <template slot-scope="{ row }">
                      <div>{{ row.name }}</div>
                      <div>券编号:{{ row.id }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="优惠方式" min-width="150" :formatter="formatterMethod" />
                  <el-table-column label="发放量" min-width="100" prop="number" />
                  <el-table-column label="领取量" min-width="100" prop="received" />
                  <el-table-column label="使用量" min-width="100" prop="used" />
                </template>
                <template v-else-if="form.prizeType === 'GIFT'">
                  <el-table-column label="赠品名称" prop="name" min-width="150" :show-overflow-tooltip="true" />
                  <el-table-column label="赠品信息" min-width="200">
                    <template slot-scope="{ row }">
                      <div class="df aic jcc">
                        <moe-image :src="row.goodsItemCover" width="80px" height="80px" />
                        <div class="df1 tal ml-10">
                          <div class="fwb">{{ row.goodsName }}</div>
                          <div class="df aic">
                            <moe-tag class="mr-5" v-for="(specItem, specIndex) in row.goodsItemName.split(',')" :key="specIndex">{{ specItem }}</moe-tag>
                          </div>
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="剩余库存" prop="stockLeft" min-width="100" />
                </template>
                <el-table-column label="操作" fixed="right" width="200">
                  <template slot-scope="{ row, $index }">
                    <el-button icon="el-icon-delete" size="small" type="danger" @click="handleDelete('prizeForm', row, $index)">删除</el-button>
                  </template>
                </el-table-column>
              </moe-table>
            </div>
            <el-form-item label="发放总量" prop="total">
              <el-input
                class="w-200"
                :value="form.total"
                placeholder="请输入"
                maxLength="6"
                @input="(value) => form.total = $moe_formatter.formatterInteger(value)">
                <template slot="append">份</template>
              </el-input>
            </el-form-item>
          </template>
          <el-form-item label="图片" prop="image">
            <moe-upload-file
              v-model="form.image"
              ossKey="SHOP_MEDIA"
              upload-text="上传图片"
              :default-file-list="imageList"
              @change="$refs.prizeForm.validateField('image')" />
          </el-form-item>
        </moe-form>
      </div>
      <template slot="footer">
        <el-button :loading="luckyGridLoad" @click="handleDialogClose()">{{ luckyGridLoad?'请稍候...':'取消' }}</el-button>
        <el-button :loading="luckyGridLoad" type="primary" @click="req_updateLotteryItem()">{{ luckyGridLoad?'请稍候...':'确定' }}</el-button>
      </template>
    </moe-dialog>

    <couponDialog
      :showDialog.sync="couponParams.showDialog"
      :multiple="false"
      @close="handleCouponClose">
    </couponDialog>

    <giftGoodsDialog
      :showDialog.sync="giftParams.showDialog"
      :multiple="false"
      @close="handleGiftClose">
    </giftGoodsDialog>
  </moe-page>
</template>

<script>
import couponDialog from '@/views/ActivityManage/components/couponDialog.vue';
import giftGoodsDialog from '@/views/ActivityManage/components/giftGoodsDialog.vue';
export default {
  name: 'LotteryManageLuckyGridAdd',
  components: {
    couponDialog,
    giftGoodsDialog
  },
  computed: {
    IS_EDIT() {
      return this.luckyGridParams.id ? true : false
    },
    ALL_RATE_COMPUTED() {
      let rateNumber = 0
      this.luckyGridParams.itemConfigList.forEach(({ rate }) => {
        rateNumber = this.$moe_math.mathAdd(rateNumber, rate);
      })
      return rateNumber;
    }
  },
  data() {
    const verify = this.$moe_verify.verifyForm;
    const checkSendCondition = (rule, value, callback) => {
      const { buySend, sendCount } = this.luckyGridParams
      if (buySend) {
        if (!value) {
          return callback(new Error(`请输入订单满足金额`));
        }
        if (this.$moe_math.mathLessThanOrEqualTo(value, 0)) {
          return callback(new Error(`订单满足金额必须大于0元`));
        }
        if (!sendCount) {
          return callback(new Error(`请输入最多获得次数`));
        }
        callback();
      } else {
        callback();
      }
    }
    const checkItemList = (rule, value, callback) => {
      const { prizeType } = this.form;
      if (!value.length) {
        if (prizeType === 'COUPON') {
          return callback(new Error(`请添加优惠券`));
        }
        if (prizeType === 'GIFT') {
          return callback(new Error(`请添加赠品`));
        }
      } else {
        callback();
      }
    }
    const checkRate = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(`请输入转到概率`));
      } else {
        if (this.$moe_math.mathEquals(this.ALL_RATE_COMPUTED, 100)) {
          callback();
        } else {
          return callback(new Error(`所有奖品几率相加必须等于100%`));
        }
      }
    }
    return {
      active: 0,
      luckyGridParams: {
        id: '',
        name: '',
        startTime: '',
        endTime: '',
        buySend: false,
        sendCondition: '',
        sendCount: '',
        itemList: [],
        itemConfigList: [],
      },
      datetime: [],
      itemListTotal: 8,
      rules: {
        name: verify.isExtent(['请输入活动名称', '长度为 2 ~ 20位'], '2~20'),
        startTime: verify.isEmpty('请选择活动时间'),
        sendCondition: [{ validator: checkSendCondition, trigger: ['blur', 'change'] }],
        itemList: verify.isEmpty('请添加奖品'),
        itemConfigList: verify.isEmpty('请添加奖品'),
        lotteryItemId: verify.isEmpty('请选择奖品'),
        rate: [{ required: true, validator: checkRate, trigger: ['blur', 'change'] }],
      },
      luckyGridLoad: false,
      form: {
        showDialog: false,
        name: '',
        prizeType: 'COUPON',
        itemList: [],
        total: '',
        image: '',
      },
      imageList: [],
      formRules: {
        name: verify.isExtent(['请输入奖品名称', '长度为 2 ~ 20位'], '2~20'),
        prizeType: verify.isEmpty('请选择奖品内容'),
        itemList: [{ required: true, validator: checkItemList, trigger: ['blur', 'change'] }],
        total: verify.isEmpty('请输入发放总量'),
        image: verify.isEmpty('请上传图片'),
      },
      couponParams: {
        showDialog: false,
      },
      giftParams: {
        showDialog: false,
      }
    }
  },
  methods: {
    formatterPrizeType({ prizeType }) {
      return this.$moe_format.getPrizeTypeList(prizeType);
    },
    formatterIndex(row, column, cellValue, index) {
      return index + 1;
    },
    formatterMethod({ condition, discount, name }) {
      const { prizeType } = this.form;
      if (prizeType === 'COUPON') {
        return `满 ${condition} 减 ${discount} 元`;
      } else if(prizeType === 'GIFT') {
        return `${name}`;
      } else {
        return '-';
      }
    },
    changeRate(value, index) {
      let row = this.luckyGridParams.itemConfigList[index];
      row.rate = this.$moe_formatter.formatterMoney(value);
      try {
        this.$refs['luckyGridForm'].validate();
      } catch {
        console.log('error');
      }
    },
    handleStep(type, newActive) {
      switch (type) {
        case 'back':
          if (newActive === 1) {
            this.$moe_layer.msgbox({
              title: '确定返回上一步?',
              message: `即将返回上一步，如有转盘设置没保存，请取消并点击「保存」按钮保存`,
              showCancelButton: true,
              confirmButtonText: '取消',
              cancelButtonText: '上一步',
            }, () => {

            }, () => {
              this.active -= 1;
            })
          } else {
            this.active = newActive;
          }
          break;
        case 'right':
          this.$refs['luckyGridForm'].validate(() => {
            if (newActive === 1) {
              const { name, startTime, endTime, buySend, sendCondition, sendCount } = this.luckyGridParams;
              const params = {
                name,
                startTime,
                endTime,
                buySend,
                sendCondition: buySend ? sendCondition : null,
                sendCount: buySend ? sendCount : null,
              };
              if (this.IS_EDIT) {
                this.active = newActive;
              } else {
                this.luckyGridLoad = true;
                this.req_addLottery(params, newActive);
              }
            } else {
              this.active = newActive;
            }
          })
          break;
      }
    },
    changePrizeType() {
      if (this.form.itemList.length) {
        this.form.itemList.splice(0);
        this.$moe_msg.warning('检测到你切换奖品内容 已清除内容');
      }
    },
    changeLotteryItemId(value, index) {
      if (value) {
        this.luckyGridParams.itemConfigList[index].image = this.luckyGridParams.itemList.find(({ id }) => id === value)?.image || '';
      } else {
        this.luckyGridParams.itemConfigList[index].image = '';
      }
    },
    /** 选择优惠券确定 */
    handleCouponClose(selectList) {
      this.form.itemList = this.$moe_lodash.cloneDeepData(selectList);
      this.couponParams.showDialog = false;
    },
    /** 选择赠品确定 */
    handleGiftClose(selectList) {
      this.form.itemList = this.$moe_lodash.cloneDeepData(selectList);
      this.giftParams.showDialog = false;
    },
    /** 删除添加的奖品 */
    handleDelete(source, { name, id }, index) {
      this.$moe_layer.confirm(`您确定要删除当前选项"${name}"吗？`, () => {
        switch (source) {
          case 'luckyGridForm':
            this.luckyGridParams.itemList.splice(index, 1);
            this.luckyGridParams.itemConfigList.forEach((item) => {
              if (item.lotteryItemId === id) {
                item.lotteryItemId = '';
                item.image = '';
              }
            });
            break;
          case 'prizeForm':
            this.form.itemList.splice(index, 1);
            break;
        }
        this.$moe_msg.success('删除成功');
      });
    },
    handleSubmit() {
      this.$refs['luckyGridForm'].validate(() => {
        this.luckyGridLoad = true;
        this.req_updateLottery(this.luckyGridParams);
      })
    },
    handleShowDialog() {
      const { prizeType } = this.form;
      switch (prizeType) {
        case 'COUPON':
          this.couponParams.showDialog = true;
          break;
        case 'GIFT':
          this.giftParams.showDialog = true;
          break;
      }
    },
    handleDialogClose() {
      this.form.showDialog = false;
      this.imageList.splice(0);
      this.$refs['prizeForm'].resetFields();
    },
    /** 创建抽奖活动 */
    async req_addLottery(params) {
      const { code, result, message } = await this.$moe_api.LUCKYGRID_API.addLottery(params);
      if (code === 200) {
        this.active += 1;
        this.$router.replace({
          query: {
            id: result.id
          }
        })
        this.req_getLotteryDetail();
      } else {
        this.$moe_msg.error(message);
      }
      this.luckyGridLoad = false;
    },
    /** 修改抽奖活动 */
    async req_updateLottery(params) {
      const { code, message } = await this.$moe_api.LUCKYGRID_API.updateLottery(params);
      if (code === 200) {
        this.$moe_msg.success('保存成功', {
          completionHandler: () => {
            this.$moe_coordinator.navigateBack();
          }
        });
      } else {
        this.$moe_msg.error(message);
      }
      this.luckyGridLoad = false;
    },
    /** 新增抽奖奖品配置 */
    async req_updateLotteryItem() {
      this.$refs['prizeForm'].validate(async () => {
        this.luckyGridLoad = true;
        const { name, prizeType, itemList, total, image } = this.form;
        let prizeId = '', newTotal = 0;
        if (['COUPON', 'GIFT'].includes(prizeType)) {
          prizeId = itemList[0].id;
          newTotal = total;
        }
        const params = {
          id: '',
          lotteryId: this.luckyGridParams.id,
          name,
          prizeType,
          prizeId,
          total: newTotal,
          image,
        };

        const { code, result, message } = await this.$moe_api.LUCKYGRID_API.updateLotteryItem(params);
        if (code === 200) {
          this.$moe_msg.success('添加奖品成功', {
            completionHandler: () => {
              this.luckyGridParams.itemList.push({
                ...params,
                id: result.id || '',
                image: this.$moe_yunHelper.getOssImageUrl(params.image),
                // imageList: [
                //   {
                //     url: this.$moe_yunHelper.getOssImageUrl(image),
                //     tempFilePath: image
                //   }
                // ]
              });
              this.luckyGridLoad = false;
              this.handleDialogClose();
            }
          });
        } else {
          this.luckyGridLoad = false;
          this.$moe_msg.error(message);
        }
      })
    },
    /** 获取抽奖活动详情 */
    async req_getLotteryDetail() {
      if (this.$route.query.id) {
        const { code, result, message } = await this.$moe_api.LUCKYGRID_API.getLotteryDetail({ id: this.$route.query.id });
        if (code === 200) {
          let { id, name, startTime, endTime, buySend, sendCondition, sendCount, itemList, itemConfigList } = result;
          this.luckyGridParams = {
            id,
            name,
            startTime,
            endTime,
            buySend,
            sendCondition,
            sendCount,
            itemList,
            itemConfigList: itemConfigList?.length ? itemConfigList.map((item) => {
              let findItem = itemList.find(({ id }) => item.lotteryItemId === id);
              return {
                ...item,
                image: findItem?.image,
              }
            }) : Array.from({ length: 8 }, (_, index) => ({
              lotteryItemId: '',
              rate: '',
              image: '',
            })),
          };

          this.datetime = [startTime, endTime];
        } else {
          this.$moe_msg.error(message);
        }
      }
    }
  },
  mounted() {
    this.req_getLotteryDetail();
  },
}
</script>