<template>
  <moe-dialog class="coupon-dialog" :show="showDialog" :title="title" width="70%" @close="handleDialogClose()" :closeOnClickModal="false">
    <div class="dialog_content">
      <moe-inquire @search="couponSearch">
        <el-form-item label="优惠券编号">
          <el-input :value="couponParams.id" @input="(value) => couponParams.id = $moe_formatter.formatterId(value)" placeholder="输入优惠券编号" maxlength="100" clearable />
        </el-form-item>

        <el-form-item label="优惠券名称">
          <el-input v-model.trim="couponParams.name" placeholder="输入优惠券名称" maxlength="100" clearable />
        </el-form-item>
      </moe-inquire>

      <div class="df1 df fdc">
        <moe-table
          v-if="showDialog"
          ref="couponTableRef"
          url="/shop/coupon/list"
          :params="couponParams"
          :numberShow="false"
          row-key="id"
          :multiple="multiple"
          :tableRowClassName="tableRowClassName"
          :row-class-name="tableRowClassName"
          :defaultSelectIds="defaultSelectIds"
          @selectChange="selectChange">
          <el-table-column :selectable="selectable" type="selection" reserve-selection label="id" width="60" />
          <el-table-column label="活动信息" min-width="150">
            <template slot-scope="{ row }">
              <div>{{ row.name }}</div>
              <div>券编号:{{ row.id }}</div>
            </template>
          </el-table-column>

          <el-table-column label="券类型" min-width="150">
            <template slot-scope="{ row }">
              {{ $moe_format.getCouponLevel(row.level) }}
            </template>
          </el-table-column>

          <el-table-column label="优惠方式" min-width="150">
            <template slot-scope="{ row }">
              {{ `满 ${row.condition} 元 减 ${row.discount} 元` }}
            </template>
          </el-table-column>

          <el-table-column label="使用时间" min-width="310">
            <div class="df fdc aic jcc" slot-scope="{ row }">
              <template v-if="row.timeType === 'TIME_RANGE'">
                <div>{{ $moe_time.getTimeStamp(row.startTime) }} 至 {{ $moe_time.getTimeStamp(row.endTime) }}</div>
                <div>活动持续：{{ $moe_time.countDown(row.endTime, row.startTime) }}</div>
              </template>
              <div v-if="row.timeType === 'RELATIVE'">{{ `领取后 ${row.effectiveDays} 天有效` }}</div>
            </div>
          </el-table-column>

          <el-table-column label="优惠券库存" min-width="150">
            <template slot-scope="{ row }">
              {{ $moe_math.mathSub(row.number, row.received) }}
            </template>
          </el-table-column>

          <el-table-column label="每人限领" min-width="150">
            <template slot-scope="{ row }">
              {{ `${row.quota === 0 ? '不限量' : row.quota}` }}
            </template>
          </el-table-column>
        </moe-table>
      </div>
    </div>
    <template slot="footer">
      <el-button @click="handleDialogClose()">取消</el-button>
      <el-button type="primary" @click="handleConfirm()">确定</el-button>
    </template>
  </moe-dialog>
</template>

<script>
export default {
  name: 'couponDialog',
  model: {
    props: 'value',
    event: 'close',
  },
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '添加优惠券',
    },
    /** 默认选中的优惠券id集合 */
    defaultSelectIds: {
      type: Array,
      default: () => [],
    },
    /** 是否可以多选 */
    multiple: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    showDialog(newValue) {
      if (!newValue) {
        this.selectList.splice(0);
      }
    }
  },
  data() {
    return {
      couponParams: {
        pageNum: 1,
        pageSize: 10,
        id: '',
        name: '',
        state: 'RECEIVING',
      },
      selectList: [],
    }
  },
  methods: {
    /** 是否可以多选 */
    selectable(row) {
      if (!this.multiple && this.selectList.length && !this.selectList.filter(({ id }) => row.id === id).length) {
        return false;
      }
      return true;
    },
    /** 更改已被选择的背景颜色 */
    tableRowClassName({ row }) {
      if (this.selectList.length && this.selectList.filter(({ id }) => row.id === id).length) {
        return 'disable-row';
      }
      return '';
    },
    couponSearch(isSearch) {
      if (!isSearch) {
        this.couponParams = {
          pageNum: 1,
          pageSize: 10,
          id: '',
          name: '',
          state: 'RECEIVING',
        }
      }
      this.$refs['couponTableRef'].clearLoadData();
    },
    selectChange(list) {
      this.selectList = list;
    },
    handleDialogClose() {
      this.couponSearch(false);
      this.$emit('cancel');
      this.$emit('update:showDialog', false);
    },
    handleConfirm() {
      if (!this.selectList.length) {
        return this.$moe_msg.warning('请选择优惠券');
      }
      this.$emit('close', this.selectList);
    },
  }
}
</script>

<style lang="scss">

</style>